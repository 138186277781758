<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    :extra-actions="extraActions"
    :allow-duplicate="false"
    :has-insert-btn="false"
    authority="Products_Product"
    single-edit
    inline-actions
    hide-edit
    hide-delete
  />
</template>

<script>
import { getUserRights } from '@/auth/utils'
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../supplierProductsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      supplierOptions: [],
      categoriesOptions: [],
      maxProducts: '',
      productsAdded: '',
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'products/suppliers',
        endpoint: 'products/suppliers',
        route: 'products',
        filtersOpen: true,
        title: {
          single: this.$i18n.t('Product'),
          plural: this.$i18n.t('Products'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'featuredImg', label: this.$i18n.t('Image'), sortable: false },
        { key: 'title', label: this.$i18n.t('name'), sortable: true },
        { key: 'supplier', label: this.$i18n.t('supplier'), sortable: false },
        { key: 'code', label: this.$i18n.t('code'), sortable: true },
        { key: 'targetPrice', label: this.$i18n.t('target price'), sortable: true },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },
    formattedColumns() {
      return [
        { key: 'featuredImg', type: 'image', width: 80 },
        { key: 'supplier', type: 'supplier' },
      ]
    },
    extraActions() {
      const rights = getUserRights()
      return [
        {
          callback: 'submitSellRequest',
          icon: 'ShoppingBagIcon',
          title: 'Sell Product',
          variant: 'primary',
          authorityValidation: rights.includes('Products_Product_Publish_Edit'),
          renderCondition: {
            key: 'partner',
            value: true,
          },
          disabled: this.productsAdded < this.maxProducts ? false : true,
        },
        {
          endpoint: 'partner-request',
          icon: 'PenToolIcon',
          title: 'Send Partner Request',
          variant: 'outline-primary',
          authorityValidation: rights.includes('Users_Partner_Connect_Edit'),
          renderCondition: {
            key: 'partner',
            value: false,
          },
        },
        {
          icon: 'EyeIcon',
          title: 'View in e-shop',
          // eslint-disable-next-line prefer-template
          href: process.env.VUE_APP_SHOP_URL + 'product/',
          appendField: 'eshopSlug',
          variant: 'outline-secondary',
          renderCondition: {
            key: 'published',
            value: true,
          },
        },
      ]
    },

    filters() {
      return [
        {
          name: 'supplier',
          label: this.$t('supplier'),
          options: this.supplierOptions,
        },
        {
          name: 'category',
          label: this.$t('category'),
          options: this.categoriesOptions,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '3',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '2',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchAllSuppliers').then(response => {
      this.supplierOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCategories').then(response => {
      this.categoriesOptions = response.data
    })
    this.maxProducts = this.$store.getters['app-common/maxProducts']
    this.productsAdded = localStorage.getItem('productsAdded')
  },
}
</script>
